<template>
  <div class="RegisterToNewsletterButton" >
    <button @click="clickOnNewsletterButton" id="register-to-newsletter-button" class="gavc-btn gavc-btn-circle">
      <i class="gavc-icon gavc-icon-envelope" ></i>
      <span class="gavc-text-icon" style="opacity: 1;">créer une alerte</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'RegisterToNewsletterButton',
  methods: {
    clickOnNewsletterButton () {
      this.$emit('clickOnButton')
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/_variables.scss';

#register-to-newsletter-button {
    background-color: $green-darken-1; 
    position: fixed;
    bottom: 50px;
    padding: 25px;
    right: 75px; 
    z-index: 1000;
}

#register-to-newsletter-button > i{
    font-size: 2em; 
}
</style>